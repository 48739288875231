import { TableFilters, TableSort } from '@redux/Tables/filtersKeys';
import { apiFilterQueryParamGenerator } from 'services/apiFilterQueryParamGenerator/apiFilterQueryParamGenerator';

import { apiClient } from '../apiClient';
import { PLATFORM_LEGAL_CORPORATION_ID } from '../constants';
import { BUSINESS_ADMIN_ROLE_CODE } from '../roles/types';
import { ApiActionResponse, ApiHydraPageableResponse } from '../types';
import {
  ApiPermissionFromUserMetadataItem,
  ApiPermissionInList,
  ApiPermissionOfLegalCorporation,
} from './types';

const ENDPOINT_USER_METADATA = '/user-metadata';
const DESACTIVATE_PERMISSION_ENDPOINT = '/permission/desactivate-permission';
const EDIT_PERMISSION_ROLE_ENDPOINT = '/permission/edit-permission-role/';
const ENDPOINT_FETCH_CURRENT_USER_PERMISSIONS = '/me/permissions';
const ENDPOINT_LEGAL_CORPORATIONS = '/legal-corporations';
const ENDPOINT_PERMISSIONS = '/permissions';

export const fetchUserPermissions = () => {
  const filters = {
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };
  return apiClient.request<ApiPermissionInList[]>(
    'GET',
    `${ENDPOINT_FETCH_CURRENT_USER_PERMISSIONS}${apiFilterQueryParamGenerator.format({ filters })}`,
  );
};

export const desactivatePermission = (permissionId: number) => {
  const filters = {
    id: permissionId.toString(),
  };
  return apiClient.request<ApiActionResponse>(
    'POST',
    `${DESACTIVATE_PERMISSION_ENDPOINT}${apiFilterQueryParamGenerator.format({ filters })}`,
  );
};

export const editPermissionRole = (permissionId: number, role: string) => {
  const endpointEditRoleFromGivenPermission = `${EDIT_PERMISSION_ROLE_ENDPOINT}${permissionId}`;
  const filters = {
    role: role,
  };
  return apiClient.request<ApiPermissionInList>(
    'PUT',
    `${endpointEditRoleFromGivenPermission}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
  );
};

export const getActiveBusinessAdminPermissionsOnLegalCorporation = (
  pageNumber?: number,
  filters?: TableFilters,
  sort?: TableSort | null,
) => {
  const endpointPermissionsFromGivenLegalCorporation = `${ENDPOINT_LEGAL_CORPORATIONS}/${PLATFORM_LEGAL_CORPORATION_ID}${ENDPOINT_PERMISSIONS}`;
  const allFilters = {
    isActive: 'true',
    'role.code': BUSINESS_ADMIN_ROLE_CODE,
    ...filters,
  };
  return apiClient.request<ApiHydraPageableResponse<ApiPermissionOfLegalCorporation>>(
    'GET',
    `${endpointPermissionsFromGivenLegalCorporation}${apiFilterQueryParamGenerator.format({
      pageNumber,
      filters: allFilters,
      sort,
    })}`,
  );
};

export const getActiveBusinessAdminPermissionsOnLegalCorporationNotInertWasteApplicationReferent = (
  pageNumber?: number,
  filters?: TableFilters,
  sort?: TableSort | null,
) => {
  const endpointPermissionsFromGivenLegalCorporation = `${ENDPOINT_LEGAL_CORPORATIONS}/${PLATFORM_LEGAL_CORPORATION_ID}${ENDPOINT_PERMISSIONS}`;
  const allFilters = {
    isActive: 'true',
    'role.code': BUSINESS_ADMIN_ROLE_CODE,
    'exists[userMetadata.inertWasteApplicationReferents]': 'false',
    ...filters,
  };
  return apiClient.request<ApiHydraPageableResponse<ApiPermissionOfLegalCorporation>>(
    'GET',
    `${endpointPermissionsFromGivenLegalCorporation}${apiFilterQueryParamGenerator.format({
      pageNumber,
      filters: allFilters,
      sort,
    })}`,
  );
};

export const getActivePermissionFromUserMetadataIdOnLegalCorporation = (userMetadataId: string) => {
  const filters = {
    'legalCorporation.id': PLATFORM_LEGAL_CORPORATION_ID,
    isActive: 'true',
  };
  return apiClient.request<ApiHydraPageableResponse<ApiPermissionFromUserMetadataItem>>(
    'GET',
    `${ENDPOINT_USER_METADATA}/${userMetadataId}${ENDPOINT_PERMISSIONS}${apiFilterQueryParamGenerator.format(
      {
        filters,
      },
    )}`,
  );
};
