import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colorPalette, getSpacing } from 'stylesheet';

import { lateralMenuStyle } from '../LateralMenu.style';

export const LateralMenuLinkContainer = styled(NavLink)`
  ${lateralMenuStyle.itemContainer};
  position: relative;
  padding: ${getSpacing(1)} 0;
  &.active {
    color: ${colorPalette.white};
    background: rgba(255, 255, 255, 0.16);
    border-radius: ${getSpacing(1)};
    &.disabled {
      pointer-events: none;
    }
  }
`;

export const LateralMenuLinkLabel = styled.p`
  ${lateralMenuStyle.itemLabel}
`;
