import { useAppDispatch, usePersistReduxStoreAndReloadPage } from '@redux/hooks';
import { getSelectedPermission, getUserPermissions, isBackOfficeUser } from '@redux/User/selectors';
import { updateSelectedPermissionId } from '@redux/User/slice';
import { HamburgerMenuIcon } from 'assets/icons/HamburgerMenuIcon';
import { SelectClientCompanyIcon } from 'assets/icons/SelectClientCompanyIcon';
import { useIsMobile } from 'breakpoints';
import { IconButton } from 'components/IconButton/IconButton';
import { SelectInput } from 'components/Inputs/SelectInput/SelectInput';
import { ChangeEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ROUTES } from 'routing/routes';
import { useUserInformation } from 'services/authentication/useUserInformation';
import { convertPermissionToSelectInputOption } from 'services/utils/permissions';

import {
  HeaderBrandImage,
  HeaderBrandImageContainer,
  HeaderClinetCompaniesSelectContainer,
  HeaderContainer,
  HeaderMenuIconContainer,
  HeaderUserCardContainer,
} from './Header.style';
import { UserCard } from './UserCard/UserCard';

const HEADER_BRAND_LOGO_FILENAME = 'HeaderBrandLogo.png';

interface HeaderProps {
  onToggleDisplayMobileMenu: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onToggleDisplayMobileMenu }) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const persistReduxStoreAndReloadPage = usePersistReduxStoreAndReloadPage();
  const userInformation = useUserInformation();
  const userPermissions = useSelector(getUserPermissions);
  const userSelectedPermission = useSelector(getSelectedPermission);
  const isBackofficeUser = useSelector(isBackOfficeUser);
  const displayCompanySelectInput =
    userInformation.isAuthenticated && userPermissions.length > 0 && !isBackofficeUser;
  const SELECT_INPUT_ID = 'userPermissionsSelectInput';

  const userPermissionsForSelectInput = useMemo(() => {
    return userPermissions.map(convertPermissionToSelectInputOption);
  }, [userPermissions]);

  const onSelectInputChange = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    dispatch(updateSelectedPermissionId(parseInt(event.target.value)));
    await persistReduxStoreAndReloadPage();
  };
  return (
    <HeaderContainer $isNotMobile={!isMobile}>
      {isMobile && (
        <HeaderMenuIconContainer>
          <IconButton displayStyle="grey" onClick={onToggleDisplayMobileMenu}>
            <HamburgerMenuIcon />
          </IconButton>
        </HeaderMenuIconContainer>
      )}

      <HeaderBrandImageContainer to={ROUTES.HOME}>
        <HeaderBrandImage src={`${process.env.PUBLIC_URL}/config/${HEADER_BRAND_LOGO_FILENAME}`} />
      </HeaderBrandImageContainer>

      {displayCompanySelectInput && (
        <HeaderClinetCompaniesSelectContainer>
          <SelectInput
            id={SELECT_INPUT_ID}
            name={SELECT_INPUT_ID}
            value={userSelectedPermission?.id}
            onChange={onSelectInputChange}
            choices={userPermissionsForSelectInput}
            startIcon={<SelectClientCompanyIcon />}
            preserveSpacingForHelperText={false}
          />
        </HeaderClinetCompaniesSelectContainer>
      )}
      {userInformation.isAuthenticated && (
        <HeaderUserCardContainer>
          <UserCard user={userInformation} />
        </HeaderUserCardContainer>
      )}
    </HeaderContainer>
  );
};
