import { setIsError } from '@redux/ApiResponseStatusGlobalDisplay/slice';
import { useAppDispatch } from '@redux/hooks';
import { getSelectedPermission } from '@redux/User/selectors';
import { addReleaseNoteToPermission } from '@redux/User/slice';
import { Button } from 'components/Button/Button';
import { Dialog } from 'components/Dialog/Dialog';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { PLATFORM_LEGAL_CORPORATION_LABEL, RELEASE_NOTES_VERSION } from 'services/api/constants';
import { createReleaseNote } from 'services/api/releaseNotes/client';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';
import { logger } from 'services/logs/logger';
import { colorPalette } from 'stylesheet';

import {
  ButtonContainer,
  ReleaseNotesDetailsContainer,
  ReleaseNotesImageContainer,
} from './ReleaseNotesDialog.style';
interface Props {
  isOpen: boolean;
  closeReleaseNotesDialog: () => void;
}

export const ReleaseNotesDialog = ({ isOpen, closeReleaseNotesDialog }: Props) => {
  const releaseNotesDialogIntl = useIntlByKey('home.release-notes-modal');
  const userPermission = useSelector(getSelectedPermission);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const onAgreementClick = async () => {
    if (userPermission) {
      setIsLoading(true);
      try {
        await createReleaseNote({ permissionId: userPermission.id });
        dispatch(
          addReleaseNoteToPermission({
            permissionId: userPermission.id,
            version: RELEASE_NOTES_VERSION,
          }),
        );
        closeReleaseNotesDialog();
      } catch (error) {
        logger.logError('Error creating release note', error);
        dispatch(setIsError(true));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={releaseNotesDialogIntl('title', {
        platformLabel: PLATFORM_LEGAL_CORPORATION_LABEL.toUpperCase(),
      })}
      showCloseIcon={true}
      onClose={onAgreementClick}
      titleColor={colorPalette.status_success}
    >
      <ReleaseNotesDetailsContainer>
        <ReleaseNotesImageContainer
          src={`${process.env.PUBLIC_URL}/release-notes/${RELEASE_NOTES_VERSION}.png`}
          alt={releaseNotesDialogIntl('img-alt-text')}
        />
        <ButtonContainer>
          <Button displayStyle={'filledOrange'} onClick={onAgreementClick} isLoading={isLoading}>
            <FormattedMessage id={releaseNotesDialogIntl('accept-conditions')} />
          </Button>
        </ButtonContainer>
      </ReleaseNotesDetailsContainer>
    </Dialog>
  );
};
