import { createTheme } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { colorPalette, fontFamily, fontSize, getSpacing } from 'stylesheet';

export const muiTheme = createTheme({
  typography: {
    fontFamily: fontFamily.main,
  },
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colorPalette.primary,
            '&:hover': {
              backgroundColor: colorPalette.secondary_600,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily.main,
          fontSize: fontSize.p1,
          lineHeight: '1.5',
          backgroundColor: colorPalette.white,
          padding: 0,
          '&.Mui-error': {
            borderColor: colorPalette.status_error,
          },
        },
        input: {
          padding: `${getSpacing(1)} ${getSpacing(2)}`,
        },
        multiline: {
          padding: `${getSpacing(1)} ${getSpacing(2)}`,
        },
        adornedEnd: {
          paddingRight: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily.main,
          margin: `${getSpacing(0.5)} 0`,
          marginLeft: 0,
          fontSize: fontSize.p3,
          lineHeight: '1.5',
          color: colorPalette.status_error,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: 'auto',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily.main,
        },
        body1: {
          fontFamily: fontFamily.main,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '.MuiIconButton-root': {
            margin: 0,
          },
        },
      },
    },
  },
});
