import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colorPalette } from 'stylesheet';

export const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'position',
})<{ position?: 'absolute' | 'static' }>(({ position = 'static' }) => ({
  position: position,
  '&.MuiCircularProgress-colorPrimary': {
    color: colorPalette.primary,
  },
}));
