import { UserApplication } from '@redux/User/selectors';
import { HomeIcon } from 'assets/icons/HomeIcon';
import { ROUTES } from 'routing/routes';
import {
  ANALYTICS_PROVIDER_ACTIONS,
  ANALYTICS_PROVIDER_CATEGORIES,
  ANALYTICS_PROVIDER_LABELS,
} from 'services/analytics/config';

import { HomePage } from '../../pages/lazyLoadedHomePage';
import { useIntlByKey } from '../../services/i18n/hooks/useIntlByKey';
import { MenuPageConfig } from './types';
import { useMenuPageConfigsWithoutHomePage } from './useMenuPageConfigsWithoutHomePage';

export const useMenuPageConfigs = (): MenuPageConfig[] => {
  const menuPageConfigsWithoutHomePage = useMenuPageConfigsWithoutHomePage();
  const lateralMenuIntl = useIntlByKey('menu');

  return [
    {
      path: ROUTES.HOME,
      icon: HomeIcon,
      applications: [UserApplication.FRONT_OFFICE, UserApplication.BACK_OFFICE],
      analyticsProviderEventConfig: {
        action: ANALYTICS_PROVIDER_ACTIONS.HOME_PAGE_VIEW,
        label: ANALYTICS_PROVIDER_LABELS.PAGE_VIEW,
        category: ANALYTICS_PROVIDER_CATEGORIES.HOME,
      },
      pageComponent: HomePage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('home'),
      },
    },
    ...menuPageConfigsWithoutHomePage,
  ];
};
