import { analytics } from 'services/analytics/analytics';

import {
  BaseMenuPageConfig,
  LateralMenuLinkConfig,
} from '../../../../hooks/useMenuPageConfigs/types';
import { LateralMenuLinkContainer, LateralMenuLinkLabel } from './LateralMenuLink.style';

type Props = BaseMenuPageConfig &
  LateralMenuLinkConfig & {
    onCloseMenu?: () => void;
  };

export const LateralMenuLink = ({
  path,
  search,
  title,
  icon: Icon,
  analyticsProviderEventConfig,
  onCloseMenu,
}: Props): JSX.Element => {
  const onLateralMenuClick = () => {
    analytics.event(
      analyticsProviderEventConfig.action,
      analyticsProviderEventConfig.label,
      analyticsProviderEventConfig.category,
    );
    onCloseMenu?.();
  };

  const pathObject = {
    pathname: path,
    search: search ?? '',
  };

  return (
    <LateralMenuLinkContainer to={pathObject} onClick={onLateralMenuClick}>
      <Icon isLateralMenuIcon={true} />
      <LateralMenuLinkLabel>{title}</LateralMenuLinkLabel>
    </LateralMenuLinkContainer>
  );
};
