import styled from 'styled-components';
import { colorPalette, getSpacing } from 'stylesheet';

export const LayoutContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
`;

export const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const MainContainer = styled.div<{ $isMobile: boolean }>`
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: ${({ $isMobile }) => getSpacing($isMobile ? 2 : 5)};
  background-color: ${colorPalette.backgroundMain};
  height: 100%;
`;
