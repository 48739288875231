import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const SelectFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelContainer = styled.label`
  margin-bottom: ${getSpacing(1)};
`;

export const SelectInputIconContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const SelectChoiceItemOverflowContent = styled.span`
  white-space: break-spaces;
`;

// Convert to sx prop style
export const informationIconButtonSx = {
  padding: 0,
  pl: () => getSpacing(1),
};
