import { useMsal } from '@azure/msal-react';
import {
  shouldReadLatestReleaseNotes,
  shouldSignLatestTermsOfAgreement,
} from '@redux/User/selectors';
import { AuthorizationConfig } from '@redux/User/types';
import { useAuthorizationService } from '@redux/User/useAuthorizationService';
import { CustomerAgreementDialog } from 'components/CustomerAgreementDialog/CustomerAgreementDialog';
import { ReleaseNotesDialog } from 'components/ReleaseNotesDialog/ReleaseNotesDialog';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { MSAL_REDIRECT_PARAMETERS } from 'services/authentication/msalConfig';
import { useAuthenticationStatus } from 'services/authentication/useAuthenticationStatus';

import { ROUTES } from './routes';

type Props = AuthorizationConfig;

export const ProtectedRoute = ({ applications, roles, isAllowedForInternalCompany }: Props) => {
  const { instance } = useMsal();
  const authenticationStatus = useAuthenticationStatus();
  const isUserAuthorized = useAuthorizationService()({
    applications,
    roles,
    isAllowedForInternalCompany,
  });
  const shouldOpenCustomerAgreementDialog = useSelector(shouldSignLatestTermsOfAgreement);
  const [hasAcceptedAgreements, setHasAcceptedAgreements] = useBoolean(false);
  const shouldOpenReleaseNotesDialog = useSelector(shouldReadLatestReleaseNotes);
  const [hasReadReleaseNotes, setHasReadReleaseNotes] = useBoolean(false);

  if (authenticationStatus.isLoading) {
    return <></>;
  }

  if (!authenticationStatus.isAuthenticated) {
    instance.loginRedirect(MSAL_REDIRECT_PARAMETERS);
    return <></>;
  }

  if (!isUserAuthorized) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }

  const isOpenCustomerAgreementDialog = shouldOpenCustomerAgreementDialog && !hasAcceptedAgreements;
  const isOpenReleaseNotesDialog = shouldOpenReleaseNotesDialog && !hasReadReleaseNotes;

  return (
    <>
      <Outlet />
      <CustomerAgreementDialog
        isOpen={isOpenCustomerAgreementDialog}
        closeCustomerAgreementDialog={() => setHasAcceptedAgreements(true)}
      />
      <ReleaseNotesDialog
        isOpen={!isOpenCustomerAgreementDialog && isOpenReleaseNotesDialog}
        closeReleaseNotesDialog={() => setHasReadReleaseNotes(true)}
      />
    </>
  );
};
