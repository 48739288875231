/* eslint-disable max-lines */

import { TableFilters, TableSort } from '@redux/Tables/filtersKeys';
import {
  NotifyMissingDocumentFormInputs,
  NotifyMissingDocumentFormKeys,
} from 'pages/InertWasteApplication/InertWasteApplicationForPriorAcceptance/Common/NotifyMissingDocumentDialog/types';
import { FieldValues } from 'react-hook-form';
import { apiFilterQueryParamGenerator } from 'services/apiFilterQueryParamGenerator/apiFilterQueryParamGenerator';

import { apiClient, sessionApiClient, unauthenticatedApiClient } from '../apiClient';
import { PLATFORM_LEGAL_CORPORATION_ID } from '../constants';
import { GenerateDownloadPresignedUrlRequestBody } from '../documents/types';
import { ApiActionResponse, ApiHydraPageableResponse } from '../types';
import {
  ApiActionResponseJobImport,
  ApiCountInertWasteApplicationsUnread,
  ApiCreateOrUpdateInertWasteApplicationResponse,
  ApiInertWasteApplicationForPriorAcceptanceForProducerOrExternalEvaluator,
  ApiInertWasteApplicationForPriorAcceptanceInList,
  ApiSearchCompanyBySiretResponse,
  ApplicationValuesForInertWasteApplicationForPriorAcceptance,
  CreateInertWasteApplicationForPriorAcceptanceBody,
  CreateInertWasteApplicationForPriorAcceptanceForSessionUserBody,
  InertWasteApplicationForPriorAcceptanceStatusCodes,
  isRawApiDuplicateInertWasteApplicationResponse,
  PartialCreateInertWasteApplicationForPriorAcceptanceBody,
  PartialCreateInertWasteApplicationForPriorAcceptanceForSessionUserBody,
  RawApiDuplicateInertWasteApplicationResponse,
} from './types';

const ENDPOINT_CREATE_OR_UPDATE_DRAFT_INERT_WASTE_APPLICATION =
  '/inert-waste-application/create-draft';
const ENDPOINT_DUPLICATE_INERT_WASTE_APPLICATION = '/inert-waste-application/duplicate';
const ENDPOINT_FINALIZE_CREATION = '/inert-waste-application/finalize-creation';
const ENDPOINT_DOWNLOAD_PDF_FROM_ID = '/inert-waste-application-download-from-id';
const ENDPOINT_DOWNLOAD_PDF_FROM_FORM_DATA = '/inert-waste-application-download-from-form-data';
const ENDPOINT_GET_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE =
  '/inert-waste-application-for-prior-acceptances';
const UNAUTHENTICATED_ENDPOINT_GET_FROM_TOKEN_AND_ID =
  '/inert-waste-application-for-prior-acceptance-from-id';
const UNAUTHENTICATED_ENDPOINT_DOWNLOAD_DAP_PDF_FROM_TOKEN_AND_ID =
  '/inert-waste-application-download-from-id';
const ENDPOINT_ELECTRONIC_SIGNATURE = '/dap/external-electronic-signature';
const ENDPOINT_EXTERNAL_SIGNING_URL = '/dap/external-electronic-signature-link';
const ENDPOINT_EXTERNAL_UPDATE_AFTER_SIGNATURE = '/dap/update-after-signature';
const ENDPOINT_DAP_UPDATE_FROM_EXTERNAL_USER = '/dap/external-update';
const ENDPOINT_REFUSAL_OF_DAP_FROM_EXTERNAL_USER = '/dap/external-refusal';
const ENDPOINT_APPROVAL_OF_DAP_FROM_EXTERNAL_USER = '/dap/external-approval';
const ENDPOINT_EVALUATE_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE =
  '/inert-waste-application/register-evaluation';
const ENDPOINT_NOTIFY_MISSING_DOCUMENTS = '/inert-waste-application/notify-missing-documents';
const BASE_ENDPOINT_INERT_WASTE_APPLICATION_VIEW = '/inert-waste-application-view';
const ENDPOINT_DELETE_INERT_WASTE_APPLICATION = '/inert-waste-application';
const ENDPOINT_UPDATE_ADDITIONAL_DOCUMENTS = '/inert-waste-application/update-additional-documents';
const ENDPOINT_REMIND_EXTERNAL_EVALUATOR_INERT_WASTE_APPLICATION =
  '/inert-waste-application/remind-evaluation';
const UNAUTHENTICATED_ENDPOINT_DOWNLOAD_DAP_DOCUMENT_PDF_FROM_TOKEN_AND_ID =
  '/inert-waste-application/generate-presigned-url';
const ENDPOINT_SIGNING_URL = '/inert-waste-application/signature-link';
const ENDPOINT_UPDATE_AFTER_SIGNATURE = '/inert-waste-application/update-after-signature';
const ENDPOINT_UPDATE_AFTER_SIGNATURE_REFUSAL =
  '/inert-waste-application/signature-refusal-from-applicant';
const ENDPOINT_SEARCH_COMPANY_BY_SIRET = '/inert-waste-application/search-company-by-siret';
const ENDPOINT_IMPORT_MDM_JOB = '/inert-waste-application/import-job';

export const createOrUpdateDraftInertWasteApplicationForPriorAcceptance = (
  partialCreateInertWasteApplicationForPriorAcceptanceBody: PartialCreateInertWasteApplicationForPriorAcceptanceBody,
  inertWasteApplicationId?: number,
) => {
  const data: CreateInertWasteApplicationForPriorAcceptanceBody = {
    ...partialCreateInertWasteApplicationForPriorAcceptanceBody,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };

  let endpoint = ENDPOINT_CREATE_OR_UPDATE_DRAFT_INERT_WASTE_APPLICATION;
  if (inertWasteApplicationId) {
    const filters = {
      inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
    };
    endpoint = `${ENDPOINT_CREATE_OR_UPDATE_DRAFT_INERT_WASTE_APPLICATION}${apiFilterQueryParamGenerator.format(
      { filters },
    )}`;
  }

  return apiClient.request<ApiCreateOrUpdateInertWasteApplicationResponse | ApiActionResponse>(
    'POST',
    endpoint,
    data,
  );
};

export const finalizeDraftInertWasteApplicationForPriorAcceptanceCreation = (
  partialCreateInertWasteApplicationForPriorAcceptanceBody: PartialCreateInertWasteApplicationForPriorAcceptanceBody,
  inertWasteApplicationId: number,
) => {
  const data: CreateInertWasteApplicationForPriorAcceptanceBody = {
    ...partialCreateInertWasteApplicationForPriorAcceptanceBody,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };

  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
  };

  return apiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_FINALIZE_CREATION}${apiFilterQueryParamGenerator.format({ filters })}`,
    data,
  );
};

export const finalizeDraftInertWasteApplicationForPriorAcceptanceCreationForSessionUser = (
  partialCreateInertWasteApplicationForPriorAcceptanceForSessionUserBody: PartialCreateInertWasteApplicationForPriorAcceptanceForSessionUserBody,
) => {
  const data: CreateInertWasteApplicationForPriorAcceptanceForSessionUserBody = {
    ...partialCreateInertWasteApplicationForPriorAcceptanceForSessionUserBody,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };

  return sessionApiClient.request<ApiActionResponse & { inertWasteApplicationId: number }>(
    'POST',
    `${ENDPOINT_FINALIZE_CREATION}`,
    data,
  );
};

export const getSigningUrlForInternalUserOnInertWasteApplication = (
  inertWasteApplicationId: number,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
  };

  return apiClient.request<{ signUrl: string }>(
    'GET',
    `${ENDPOINT_SIGNING_URL}${apiFilterQueryParamGenerator.format({ filters })}`,
  );
};

export const getSigningUrlForInternalUserOnInertWasteApplicationForSessionUser = (
  inertWasteApplicationId: number,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
  };

  return sessionApiClient.request<{ signUrl: string }>(
    'GET',
    `${ENDPOINT_SIGNING_URL}${apiFilterQueryParamGenerator.format({ filters })}`,
  );
};

export const updateInertWasteApplicationAfterSignatureRefusalForApplicant = (
  inertWasteApplicationId: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId,
  };

  return apiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_UPDATE_AFTER_SIGNATURE_REFUSAL}${apiFilterQueryParamGenerator.format({ filters })}`,
  );
};

export const updateInertWasteApplicationAfterSignatureForAuthenticatedUser = (
  inertWasteApplicationId: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId,
  };

  return apiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_UPDATE_AFTER_SIGNATURE}${apiFilterQueryParamGenerator.format({ filters })}`,
  );
};

export const updateInertWasteApplicationAfterSignatureForSessionUser = (
  inertWasteApplicationId: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId,
  };

  return sessionApiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_UPDATE_AFTER_SIGNATURE}${apiFilterQueryParamGenerator.format({ filters })}`,
  );
};

export const getSigningUrlForExternalUserOnInertWasteApplication = (
  inertWasteApplicationId: string,
  accessToken: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
    accessToken,
  };

  return unauthenticatedApiClient.request<{ signUrl: string }>(
    'GET',
    `${ENDPOINT_EXTERNAL_SIGNING_URL}${apiFilterQueryParamGenerator.format({ filters })}`,
  );
};

export const updateInertWasteApplicationAfterSignatureForUnauthenticatedUser = (
  inertWasteApplicationId: string,
  accessToken: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId,
    accessToken,
  };

  return unauthenticatedApiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_EXTERNAL_UPDATE_AFTER_SIGNATURE}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
  );
};

export const downloadInertWasteApplicationForPriorAcceptancePdfFromIdForProducer = (
  inertWasteApplicationForPriorAcceptanceId: string,
  accessToken: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationForPriorAcceptanceId,
    accessToken,
  };
  return unauthenticatedApiClient.request<BlobPart>(
    'GET',
    `${UNAUTHENTICATED_ENDPOINT_DOWNLOAD_DAP_PDF_FROM_TOKEN_AND_ID}${apiFilterQueryParamGenerator.format(
      {
        filters,
      },
    )}`,
    null,
    'blob',
  );
};

export const duplicateInertWasteApplicationForPriorAcceptanceFromId = async (
  inertWasteApplicationForPriorAcceptanceId: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationForPriorAcceptanceId,
  };
  const response = await apiClient.request<
    ApiActionResponse | RawApiDuplicateInertWasteApplicationResponse
  >(
    'GET',
    `${ENDPOINT_DUPLICATE_INERT_WASTE_APPLICATION}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
  );
  if (isRawApiDuplicateInertWasteApplicationResponse(response)) {
    return {
      ...response,
      applicationValues: JSON.parse(
        response.applicationValues,
      ) as ApplicationValuesForInertWasteApplicationForPriorAcceptance,
    };
  }
  return response;
};

export const downloadInertWasteApplicationForPriorAcceptancePdfFromId = (id: number) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: id.toString(),
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };
  return apiClient.request<BlobPart>(
    'GET',
    `${ENDPOINT_DOWNLOAD_PDF_FROM_ID}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
    null,
    'blob',
  );
};

export const downloadInertWasteApplicationForPriorAcceptancePdfFromFormDataForSessionUser = (
  applicationValues: FieldValues,
) => {
  const filters = {
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };
  return sessionApiClient.request<BlobPart>(
    'POST',
    `${ENDPOINT_DOWNLOAD_PDF_FROM_FORM_DATA}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
    { applicationValues },
    'blob',
  );
};

export const getInertWasteApplicationForPriorAcceptance = (id: number) => {
  return apiClient.request<ApiInertWasteApplicationForPriorAcceptanceInList>(
    'GET',
    `${ENDPOINT_GET_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE}/${id}`,
  );
};

const getInertWasteApplicationForPriorAcceptanceWithFilters = (
  pageNumber: number,
  filters?: TableFilters,
  sort?: TableSort | null,
) => {
  const allFilters = {
    'legalCorporation.id': PLATFORM_LEGAL_CORPORATION_ID,
    isActive: 'true',
    ...filters,
  };
  return apiClient.request<
    ApiHydraPageableResponse<ApiInertWasteApplicationForPriorAcceptanceInList>
  >(
    'GET',
    `${ENDPOINT_GET_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE}${apiFilterQueryParamGenerator.format(
      { pageNumber, filters: allFilters, sort },
    )}`,
  );
};

export const getInertWasteApplicationForPriorAcceptanceWithAuthorMetadataId = (
  authorMetadataId: string,
  clientCompanyId: number,
  pageNumber: number,
  filters?: TableFilters,
  sort?: TableSort | null,
  // eslint-disable-next-line max-params
) => {
  const allFilters = {
    'author.id': authorMetadataId,
    'clientCompany.id': clientCompanyId.toString(),
    ...filters,
  };

  return getInertWasteApplicationForPriorAcceptanceWithFilters(pageNumber, allFilters, sort);
};

export const getInertWasteApplicationForPriorAcceptanceWithBusinessReferentId = (
  businessReferentId: string,
  pageNumber: number,
  filters?: TableFilters,
  sort?: TableSort | null,
) => {
  const allFilters = {
    'clientCompany.businessReferents.userMetadata.id': businessReferentId,
    'clientCompany.businessReferents.isActive': 'true',
    excludeStatusCode: InertWasteApplicationForPriorAcceptanceStatusCodes.DRAFT,
    ...filters,
  };

  return getInertWasteApplicationForPriorAcceptanceWithFilters(pageNumber, allFilters, sort);
};

export const getInertWasteApplicationForPriorAcceptanceWithClientCompanyId = (
  clientCompanyId: number,
  pageNumber: number,
  filters?: TableFilters,
  sort?: TableSort | null,
) => {
  const allFilters = {
    'clientCompany.id': clientCompanyId.toString(),
    ...filters,
  };

  return getInertWasteApplicationForPriorAcceptanceWithFilters(pageNumber, allFilters, sort);
};

export const getAllInertWasteApplicationForPriorAcceptanceOfLegalCorporation = (
  pageNumber: number,
  filters?: TableFilters,
  sort?: TableSort | null,
) => {
  return getInertWasteApplicationForPriorAcceptanceWithFilters(pageNumber, filters, sort);
};

export const getInertWasteApplicationForPriorAcceptanceFromIdAndTokenUnauthenticated = (
  id: string,
  accessToken: string,
) => {
  const filters = {
    id,
    accessToken,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };
  return unauthenticatedApiClient.request<ApiInertWasteApplicationForPriorAcceptanceForProducerOrExternalEvaluator>(
    'GET',
    `${UNAUTHENTICATED_ENDPOINT_GET_FROM_TOKEN_AND_ID}${apiFilterQueryParamGenerator.format({
      filters,
    })}
      `,
  );
};

export const getDownloadPresignedUrlForUnauthenticatedInertWasteApplicationDocuments = (
  documentUrl: string,
  fileName: string,
  isForPreview: boolean,
  dapId: number,
  accessToken: string,
  // eslint-disable-next-line max-params
) => {
  const data: GenerateDownloadPresignedUrlRequestBody = {
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
    blobUrl: documentUrl,
    isForPreview,
    downloadedFileName: fileName,
  };
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: dapId.toString(),
    accessToken,
  };
  return unauthenticatedApiClient.request<string>(
    'POST',
    `${UNAUTHENTICATED_ENDPOINT_DOWNLOAD_DAP_DOCUMENT_PDF_FROM_TOKEN_AND_ID}${apiFilterQueryParamGenerator.format(
      { filters },
    )}`,
    data,
  );
};

export const initiateElectronicSignatureForProducer = (
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance,
  inertWasteApplicationId: string,
  accessToken: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
    accessToken,
  };
  const data = {
    applicationValues,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };
  return unauthenticatedApiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_ELECTRONIC_SIGNATURE}${apiFilterQueryParamGenerator.format({ filters })}`,
    data,
  );
};

export const updateDAPForExternalUser = (
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance,
  inertWasteApplicationId: string,
  accessToken: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
    accessToken,
  };
  const data = {
    applicationValues,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };
  return unauthenticatedApiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_DAP_UPDATE_FROM_EXTERNAL_USER}${apiFilterQueryParamGenerator.format({ filters })}`,
    data,
  );
};

export const updateAdditionalDocumentsOnInertWasteApplication = (
  id: number,
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance,
): Promise<ApiActionResponse> => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: id.toString(),
  };
  const data = {
    applicationValues,
  };
  return apiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_UPDATE_ADDITIONAL_DOCUMENTS}${apiFilterQueryParamGenerator.format({ filters })}`,
    data,
  );
};

export const evaluateInertWasteApplicationForPriorAcceptance = (
  id: number,
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance,
  userMetadataId: string,
): Promise<ApiActionResponse> => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: id.toString(),
    evaluatorUserMetadataId: userMetadataId,
  };
  const data = {
    applicationValues,
  };
  return apiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_EVALUATE_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE}${apiFilterQueryParamGenerator.format(
      { filters },
    )}`,
    data,
  );
};

export const notifyMissingDocumentOnInertWasteApplicationForPriorAcceptance = (
  inertWasteApplicationId: number,
  formData: NotifyMissingDocumentFormInputs,
  accessToken?: string,
): Promise<ApiActionResponse> => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
    accessToken: accessToken || '',
  };
  const data = {
    documentsList: formData[NotifyMissingDocumentFormKeys.DOCUMENTS_LIST],
  };
  const client = accessToken ? unauthenticatedApiClient : apiClient;
  return client.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_NOTIFY_MISSING_DOCUMENTS}${apiFilterQueryParamGenerator.format({ filters })}`,
    data,
  );
};

export const refusalOfDapFromExternalUser = (
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance,
  inertWasteApplicationId: string,
  accessToken: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
    accessToken,
  };
  const data = {
    applicationValues,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };
  return unauthenticatedApiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_REFUSAL_OF_DAP_FROM_EXTERNAL_USER}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
    data,
  );
};

export const searchCompanyUsingSiret = async (
  inertWasteApplicationId: string,
  accessToken: string,
  siret: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
    accessToken,
    siret,
  };
  const response = await unauthenticatedApiClient.request<ApiSearchCompanyBySiretResponse>(
    'GET',
    `${ENDPOINT_SEARCH_COMPANY_BY_SIRET}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
  );
  if (response === '') return null;
  return response;
};

export const searchCompanyUsingSiretForSessionUser = async (siret: string) => {
  const filters = {
    siret,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };
  const response = await sessionApiClient.request<ApiSearchCompanyBySiretResponse>(
    'GET',
    `${ENDPOINT_SEARCH_COMPANY_BY_SIRET}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
  );
  if (response === '') return null;
  return response;
};

export const searchCompanyUsingSiretForAuthenticatedUser = async (
  inertWasteApplicationId: number,
  siret: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
    siret,
  };
  const response = await apiClient.request<ApiSearchCompanyBySiretResponse>(
    'GET',
    `${ENDPOINT_SEARCH_COMPANY_BY_SIRET}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
  );
  if (response === '') return null;
  return response;
};

export const approvalOfDapFromExternalUser = (
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance,
  inertWasteApplicationId: string,
  accessToken: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationId.toString(),
    accessToken,
  };
  const data = {
    applicationValues,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };
  return unauthenticatedApiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_APPROVAL_OF_DAP_FROM_EXTERNAL_USER}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
    data,
  );
};

export const setInertWasteApplicationAsRead = (
  inertWasteApplicationForPriorAcceptanceId: number,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationForPriorAcceptanceId.toString(),
  };
  return apiClient.request<ApiActionResponse>(
    'POST',
    `${BASE_ENDPOINT_INERT_WASTE_APPLICATION_VIEW}/set-read${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
  );
};

export const getNumberOfUnreadInertWasteApplicationsForPermission = (permissionId: number) => {
  const endpoint = `${BASE_ENDPOINT_INERT_WASTE_APPLICATION_VIEW}/number-unread`;

  const allFilters = {
    permissionId: permissionId.toString(),
  };

  return apiClient.request<ApiCountInertWasteApplicationsUnread>(
    'GET',
    `${endpoint}${apiFilterQueryParamGenerator.format({ filters: allFilters })}`,
  );
};

export const deleteInertWasteApplicationForPriorAcceptance = (
  inertWasteApplicationForPriorAcceptanceId: number,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationForPriorAcceptanceId.toString(),
  };
  return apiClient.request<ApiActionResponse>(
    'DELETE',
    `${ENDPOINT_DELETE_INERT_WASTE_APPLICATION}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
  );
};

export const remindExternalEvaluatorOfInertWasteApplicationForPriorAcceptance = (
  inertWasteApplicationForPriorAcceptanceId: number,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationForPriorAcceptanceId.toString(),
  };
  return apiClient.request<ApiActionResponse>(
    'POST',
    `${ENDPOINT_REMIND_EXTERNAL_EVALUATOR_INERT_WASTE_APPLICATION}${apiFilterQueryParamGenerator.format(
      {
        filters,
      },
    )}`,
  );
};

export const importMdmJob = (
  inertWasteApplicationForPriorAcceptanceId: number,
  selectedSiteId: string,
  jobMdmId: string,
) => {
  const filters = {
    inertWasteApplicationForPriorAcceptanceId: inertWasteApplicationForPriorAcceptanceId.toString(),
    siteId: selectedSiteId,
    jobMdmId: jobMdmId.toString(),
  };
  return apiClient.request<ApiActionResponseJobImport | ApiActionResponse>(
    'POST',
    `${ENDPOINT_IMPORT_MDM_JOB}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
  );
};
