import { IconButton, InputAdornment, MenuItem, TextFieldProps } from '@mui/material';
import { InformationIcon } from 'assets/icons/InformationIcon';

import { StyledTextField } from '../Input.style';
import {
  informationIconButtonSx,
  LabelContainer,
  SelectChoiceItemOverflowContent,
  SelectFieldContainer,
  SelectInputIconContainer,
} from './SelectInput.style';

export type SelectChoice = {
  label: string;
  value: string | number;
};
type AdditionalSelectInputProps = {
  startIcon?: JSX.Element;
  preserveSpacingForHelperText?: boolean;
  width?: number;
  choices: SelectChoice[];
  displayEmpty?: boolean;
  onInformationButtonClick?: () => void;
};

export type SelectInputProps = TextFieldProps & AdditionalSelectInputProps;

export const SelectInput = ({
  id,
  name,
  label,
  value,
  onChange,
  error,
  helperText,
  startIcon,
  preserveSpacingForHelperText = true,
  width,
  choices,
  required,
  disabled,
  onInformationButtonClick,
  displayEmpty = false,
}: SelectInputProps): JSX.Element => {
  const inputProps = startIcon && {
    startAdornment: (
      <InputAdornment position="start">
        <SelectInputIconContainer>{startIcon}</SelectInputIconContainer>
      </InputAdornment>
    ),
  };

  return (
    <SelectFieldContainer>
      {label && (
        <LabelContainer htmlFor={id}>
          {label + (required ? ' *' : '')}
          {onInformationButtonClick && (
            <IconButton onClick={onInformationButtonClick} sx={informationIconButtonSx}>
              <InformationIcon color="black" />
            </IconButton>
          )}
        </LabelContainer>
      )}
      <StyledTextField
        $width={width}
        id={id}
        name={name}
        value={value}
        select
        onChange={onChange}
        error={error}
        helperText={helperText ?? (preserveSpacingForHelperText ? ' ' : null)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        SelectProps={{
          MenuProps: {
            sx: {
              height: '300px',
              width: width ? `${width}px` : '50%',
            },
            anchorOrigin: {
              vertical: 'bottom' as const,
              horizontal: 'left' as const,
            },
            transformOrigin: {
              vertical: 'top' as const,
              horizontal: 'left' as const,
            },
          },
          displayEmpty,
        }}
        InputProps={inputProps}
        disabled={disabled}
      >
        {choices.map((choice) => (
          <MenuItem key={choice.value} value={choice.value}>
            <SelectChoiceItemOverflowContent>{choice.label}</SelectChoiceItemOverflowContent>
          </MenuItem>
        ))}
      </StyledTextField>
    </SelectFieldContainer>
  );
};
