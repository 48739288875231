import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { colorPalette, fontFamily, fontSize, getSpacing } from 'stylesheet';

interface StyledTextFieldProps {
  $width?: number;
  $isLabelAnimated?: boolean;
  $isNumberInput?: boolean;
  $customContainer?: boolean;
}

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== '$width' &&
    prop !== '$isLabelAnimated' &&
    prop !== '$isNumberInput' &&
    prop !== '$customContainer',
})<StyledTextFieldProps>(({ $width, $isLabelAnimated, $isNumberInput, $customContainer }) => ({
  '& .MuiOutlinedInput-root': {
    fontFamily: fontFamily.main,
    fontSize: fontSize.p1,
    lineHeight: '1.5',
    backgroundColor: colorPalette.white,
    color: colorPalette.grey_600,
    borderColor: colorPalette.grey_400,
    width: $width ? `${$width}px` : '100%',
    padding: 0,

    '& .MuiSelect-root': {
      padding: `${getSpacing(1)} ${getSpacing(4)} ${getSpacing(1)} ${getSpacing(2)}`,
      '&:focus': {
        backgroundColor: colorPalette.white,
      },
    },

    '&.Mui-focused fieldset': {
      border: `1px solid ${colorPalette.grey_600}`,
    },
  },

  ...($isLabelAnimated && {
    '& .MuiOutlinedInput-input': {
      padding: `${getSpacing(1)} ${getSpacing(2)} ${getSpacing(1)} ${getSpacing(1)}`,
    },
    '& .MuiInputLabel-formControl': {
      top: '-5px',
      '&.Mui-focused': {
        color: colorPalette.grey_600,
        top: '0px',
      },
      '&.MuiInputLabel-shrink': {
        top: '0px',
      },
      '&.Mui-error': {
        color: colorPalette.status_error,
      },
    },
  }),

  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: colorPalette.status_error,
  },

  '& .MuiFormHelperText-contained': {
    margin: `${getSpacing(0.5)} 0`,
    fontFamily: fontFamily.main,
    fontSize: fontSize.p3,
    lineHeight: '1.5',
    color: colorPalette.status_error,
  },

  '& .MuiOutlinedInput-multiline': {
    padding: `${getSpacing(1)} ${getSpacing(4)} ${getSpacing(1)} ${getSpacing(2)}`,
  },

  ...($isNumberInput && {
    '& input': {
      textAlign: 'center',
      WebkitAppearance: 'textfield',
      MozAppearance: 'textfield',
      appearance: 'textfield',
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
      },
    },
  }),

  ...($customContainer && {
    '& .MuiInputBase-root': {
      padding: '0 !important',
      width: '100% !important',
      '& input:disabled': {
        background: colorPalette.grey_300,
        borderColor: colorPalette.grey_400,
        color: colorPalette.grey,
      },
    },
  }),
}));
