import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { colorPalette } from 'stylesheet';

export type IconButtonDisplayStyle = 'orange' | 'blue' | 'grey' | 'red';

export interface StyledIconButtonProps extends IconButtonProps {
  displayStyle: IconButtonDisplayStyle;
}

const getIconColor = (displayStyle: IconButtonDisplayStyle) => {
  switch (displayStyle) {
    case 'grey':
      return colorPalette.greyMain;
    case 'orange':
      return colorPalette.secondary_600;
    case 'blue':
      return colorPalette.blue_600;
    case 'red':
      return colorPalette.status_error;
  }
};

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'displayStyle',
})<StyledIconButtonProps>(({ displayStyle }) => ({
  color: getIconColor(displayStyle),
  width: '50px',
  height: '50px',
  '&.MuiIconButton-sizeSmall': {
    width: '40px',
    height: '40px',
  },
}));
