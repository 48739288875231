import {
  getError,
  getIsError,
  getIsLoading,
} from '@redux/ApiResponseStatusGlobalDisplay/selectors';
import { setError, setIsError, setIsLoading } from '@redux/ApiResponseStatusGlobalDisplay/slice';
import { useAppDispatch } from '@redux/hooks';
import { ApiErrorDialog } from 'components/ApiResponseStatusGlobalDisplay/ApiErrorDialog/ApiErrorDialog';
import { FullScreenLoader } from 'components/ApiResponseStatusGlobalDisplay/FullScreenLoader/FullScreenLoader';
import { useSelector } from 'react-redux';

export const ApiResponseStatusGlobalDisplay = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getIsLoading);
  const isError = useSelector(getIsError);
  const error = useSelector(getError);

  return (
    <>
      <FullScreenLoader isVisible={isLoading} />
      <ApiErrorDialog
        isOpen={isError}
        error={error}
        onClose={() => {
          dispatch(setIsLoading(false));
          dispatch(setIsError(false));
          dispatch(setError(undefined));
        }}
      />
    </>
  );
};
