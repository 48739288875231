import { Drawer } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';

export const StyledDrawer = muiStyled(Drawer, {
  shouldForwardProp: (prop) => prop !== '$widthInPercent',
})<{ $widthInPercent?: number }>(({ $widthInPercent, theme }) => ({
  '& .MuiDrawer-paper': {
    ...($widthInPercent ? { width: `${$widthInPercent}%` } : { minWidth: '40%', maxWidth: '60%' }),
    padding: `${getSpacing(2)} ${getSpacing(4)}`,
    backgroundColor: colorPalette.backgroundMain,
  },
}));

// Keep existing styled-components
export const SidePanelHeader = styled.div`
  min-height: ${getSpacing(8)};
  border-bottom: 1px solid ${colorPalette.grey_400};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleIconAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  ${typography.title_xsmall};
`;

export const TitleIconContainer = styled.div`
  width: ${getSpacing(4)};
  height: ${getSpacing(4)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorPalette.greyMain};
  margin-right: ${getSpacing(2)};
`;
