import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { getSelectedPermission, UserApplication } from '@redux/User/selectors';
import { storePermissions } from '@redux/User/slice';
import {
  ApiResponseStatus,
  ApiResponseStatusSectionDisplay,
} from 'components/ApiResponseStatusSectionDisplay/ApiResponseStatusSectionDisplay';
import { Layout } from 'components/Layout/Layout';
import { useMenuPageConfigs } from 'hooks/useMenuPageConfigs/useMenuPageConfigs';
import { useTarteAuCitron } from 'hooks/useTarteAuCitron';
import {
  BusinessReferentsPage,
  ClientCompanyBusinessReferentsPage,
  ClientCompanyHistoryPage,
  ClientCompanyMainPage,
  ClientCompanyUsersPage,
  ElectronicSignatureEndPage,
  EvaluatorElectronicSignatureEnd,
  ExternalEvaluationPage,
  ExternalUserEvaluationSuccessAsyncPage,
  ExternalUserSynchronizationFailurePage,
  InertWasteApplicationGeneratorPage,
  InertWasteApplicationGeneratorSuccessPage,
  InvitationProcessEndPage,
  NotFoundPage,
  ProducerFormPage,
  SubscriptionRequestPage,
  SubscriptionRequestSuccessPage,
} from 'pages/lazyLoadedPages';
import { FunctionComponent, Suspense, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-use';
import { ProtectedRoute } from 'routing/ProtectedRoute';
import { ROUTES, UNAUTHENTICATED_ROUTES } from 'routing/routes';
import { analytics } from 'services/analytics/analytics';
import { fetchUserPermissions } from 'services/api/permissions/client';
import { CLIENT_ADMIN_ROLE_CODE, CLIENT_CONTRIBUTOR_ROLE_CODE } from 'services/api/roles/types';
import { useAuthenticationStatus } from 'services/authentication/useAuthenticationStatus';
import { logger } from 'services/logs/logger';

export const App: FunctionComponent = () => {
  useTarteAuCitron();
  const intl = useIntl();
  const apiResponseStatusSectionDisplayConfiguration = {
    errorStatusTitle: intl.formatMessage({
      id: 'app.error-response.title',
    }),
    errorStatusDescription: intl.formatMessage({
      id: 'app.error-response.description',
    }),
  };
  const [apiResponseStatus, setApiResponseStatus] = useState(ApiResponseStatus.LOADING);
  const authenticationStatus = useAuthenticationStatus();
  const dispatch = useAppDispatch();
  const location = useLocation();
  let shouldAuthenticateUser = true;
  if (location.pathname && UNAUTHENTICATED_ROUTES.includes(location.pathname)) {
    shouldAuthenticateUser = false;
  }
  const fetchAndStoreUserPermissions = useCallback(async () => {
    try {
      const permissions = await fetchUserPermissions();
      dispatch(storePermissions(permissions));
      setApiResponseStatus(ApiResponseStatus.SUCCESS);
    } catch (error) {
      logger.logError('Error fetching user permissions', error);
      setApiResponseStatus(ApiResponseStatus.ERROR);
    }
  }, [dispatch]);

  useEffect(() => {
    if (shouldAuthenticateUser) {
      if (authenticationStatus.isLoading) {
        setApiResponseStatus(ApiResponseStatus.LOADING);
      } else {
        if (authenticationStatus.isAuthenticated) {
          fetchAndStoreUserPermissions();
        } else {
          setApiResponseStatus(ApiResponseStatus.SUCCESS);
        }
      }
    } else {
      setApiResponseStatus(ApiResponseStatus.SUCCESS);
    }
  }, [
    fetchAndStoreUserPermissions,
    authenticationStatus.isLoading,
    authenticationStatus.isAuthenticated,
    shouldAuthenticateUser,
  ]);
  const menuPageConfigs = useMenuPageConfigs();

  const selectedPermission = useAppSelector(getSelectedPermission);

  useEffect(() => {
    if (selectedPermission) {
      analytics.setUserConfig(selectedPermission.userUniqueHash);
    }
  }, [selectedPermission]);

  return (
    <ApiResponseStatusSectionDisplay
      configuration={apiResponseStatusSectionDisplayConfiguration}
      status={apiResponseStatus}
    >
      <BrowserRouter>
        <Suspense fallback={<ApiResponseStatusSectionDisplay status={ApiResponseStatus.LOADING} />}>
          <Routes>
            <Route
              path={ROUTES.ELECTRONIC_SIGNATURE_END}
              element={<ElectronicSignatureEndPage />}
            />
            <Route
              path={ROUTES.INERT_WASTE_APPLICATION_GENERATOR}
              element={<InertWasteApplicationGeneratorPage />}
            />
            <Route
              path={ROUTES.INERT_WASTE_APPLICATION_GENERATOR_SUCCESS}
              element={<InertWasteApplicationGeneratorSuccessPage />}
            />
            <Route path={ROUTES.B2C_SILENT_REDIRECT} element={null} />
            <Route path={ROUTES.SUBSCRIPTION_REQUEST} element={<SubscriptionRequestPage />} />
            <Route
              path={ROUTES.SUBSCRIPTION_REQUEST_SUCCESS}
              element={<SubscriptionRequestSuccessPage />}
            />
            <Route
              path={ROUTES.INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION}
              element={<ExternalEvaluationPage />}
            />
            <Route
              path={ROUTES.INERT_WASTE_APPLICATION_SIGNATURE_ASYNC_SUCCESS}
              element={<ExternalUserEvaluationSuccessAsyncPage />}
            />
            <Route
              path={ROUTES.INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION_SIGNATURE_END}
              element={<EvaluatorElectronicSignatureEnd />}
            />
            <Route
              path={ROUTES.INERT_WASTE_APPLICATION_EVALUATION_ASYNC_SUCCESS}
              element={<ExternalUserEvaluationSuccessAsyncPage />}
            />
            <Route
              path={ROUTES.INERT_WASTE_APPLICATION_EXTERNAL_SYNCHRONIZATION_FAILURE}
              element={<ExternalUserSynchronizationFailurePage />}
            />
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate to={ROUTES.HOME} replace />} />
              {menuPageConfigs.map((menuPageConfig) => {
                const Component = menuPageConfig.pageComponent;
                return (
                  <Route
                    key={menuPageConfig.linkPath ?? menuPageConfig.path}
                    element={
                      <ProtectedRoute
                        applications={menuPageConfig.applications}
                        roles={menuPageConfig.roles}
                        isAllowedForInternalCompany={menuPageConfig.isAllowedForInternalCompany}
                      />
                    }
                  >
                    <Route path={menuPageConfig.path} element={<Component />} />
                  </Route>
                );
              })}
              <Route
                path={ROUTES.INERT_WASTE_APPLICATION_SIGNATURE_FROM_WASTE_PRODUCER}
                element={<ProducerFormPage />}
              />
              <Route element={<ProtectedRoute applications={[UserApplication.BACK_OFFICE]} />}>
                <Route path={ROUTES.CLIENT_COMPANY} element={<ClientCompanyMainPage />} />
                <Route path={ROUTES.CLIENT_COMPANY_USERS} element={<ClientCompanyUsersPage />} />
                <Route
                  path={ROUTES.CLIENT_COMPANY_HISTORY}
                  element={<ClientCompanyHistoryPage />}
                />
                <Route
                  path={ROUTES.CLIENT_COMPANY_BUSINESS_REFERENTS}
                  element={<ClientCompanyBusinessReferentsPage />}
                />
              </Route>
              <Route
                element={
                  <ProtectedRoute
                    applications={[UserApplication.FRONT_OFFICE]}
                    roles={[CLIENT_ADMIN_ROLE_CODE, CLIENT_CONTRIBUTOR_ROLE_CODE]}
                  />
                }
              >
                <Route path={ROUTES.BUSINESS_REFERENTS} element={<BusinessReferentsPage />} />
              </Route>
              <Route path={ROUTES.INVITATION_PROCESS_END} element={<InvitationProcessEndPage />} />
              <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />
              <Route path={ROUTES.HEALTH_CHECK_FRONTEND} element={null} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ApiResponseStatusSectionDisplay>
  );
};
