// Dialog.style.ts
import { Dialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import { styled as scStyled } from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';

export type DialogStyleProps = {
  widthInPercent?: number;
  heightInPercent?: number;
  borderTopColor?: string;
  fullPageDialog?: boolean;
  minHeightPx?: number;
};

export const StyledDialog = styled(Dialog)<{
  $widthInPercent?: number;
  $heightInPercent?: number;
  $borderTopColor?: string;
  $fullPageDialog?: boolean;
  $minHeightPx?: number;
}>(
  ({
    $widthInPercent,
    $heightInPercent,
    $borderTopColor = colorPalette.white,
    $fullPageDialog,
    $minHeightPx,
  }) => ({
    '& .MuiDialog-paper': {
      padding: `${getSpacing(1)} ${getSpacing(3)} ${getSpacing(3)} ${getSpacing(3)}`,
      borderTop: `${getSpacing(2)} solid ${$borderTopColor}`,
      ...($widthInPercent
        ? { width: `${$widthInPercent}%`, maxWidth: `${$widthInPercent}%` }
        : { minWidth: '400px', maxWidth: '800px' }),
      ...($heightInPercent
        ? { height: `${$heightInPercent}%` }
        : { minHeight: '200px', maxHeight: '90%' }),
      borderRadius: getSpacing(2),
      ...($fullPageDialog && {
        margin: 0,
        minHeight: '100%',
        minWidth: '100%',
      }),
      ...($minHeightPx && { minHeight: `${$minHeightPx}px` }),
    },
  }),
);

export const StyledDialogContent = styled(DialogContent)({
  padding: 0,
});

export const StyledDialogHeader = scStyled.div<{ $noMarginBottom?: boolean }>(
  ({ $noMarginBottom }) => ({
    marginBottom: $noMarginBottom ? 0 : getSpacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
);

export const TitleContainer = scStyled.div<{ $showCloseIcon: boolean; color?: string }>(
  ({ $showCloseIcon, color }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: $showCloseIcon ? 'start' : 'center',
    ...typography.title_xsmall,
    color: color || 'currentColor',
  }),
);

export const TitleIconContainer = scStyled.div<{ titleIconColor?: string }>(
  ({ titleIconColor }) => ({
    width: getSpacing(5),
    height: getSpacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: titleIconColor,
    marginRight: getSpacing(2),
  }),
);
