import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Draft } from 'immer';

import { BUSINESS_REFERENTS_MANAGEMENT_FILTERS_INITIAL_VALUES } from './filters/businessReferentsManagement';
import { CLIENT_COMPANIES_FILTERS_INITIAL_VALUES } from './filters/clientCompanies';
import { CLIENT_REQUESTS_FILTERS_INITIAL_VALUES } from './filters/clientRequests';
import {
  DELIVERY_NOTES_FILTERS_INITIAL_VALUES,
  DeliveryNotesFiltersKeys,
} from './filters/deliveryNotes';
import { INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_FILTERS_INITIAL_VALUES } from './filters/inertWasteApplicationForPriorAcceptance';
import { INERT_WASTE_APPLICATION_REFERENTS_FILTERS_INITIAL_VALUES } from './filters/inertWasteApplicationReferents';
import { INVOICES_FILTERS_INITIAL_VALUES } from './filters/invoices';
import { USER_CLIENT_COMPANIES_FILTERS_INITIAL_VALUES } from './filters/userClientCompanies';
import { FilterKey, TableFilters, TableSort } from './filtersKeys';
import { TableKey } from './tableKey';
import { TablesState } from './types';

const initialTableState = {
  selectedIds: [],
  selectAll: false,
  isReloading: false,
  filters: {} as TableFilters,
  sort: null,
};
const initialState: TablesState = {
  [TableKey.DELIVERY_NOTES]: {
    ...initialTableState,
    filters: {
      [FilterKey.SEARCH]: '',
      ...DELIVERY_NOTES_FILTERS_INITIAL_VALUES,
    },
  },
  [TableKey.CLIENT_COMPANIES]: {
    ...initialTableState,
    filters: { [FilterKey.SEARCH]: '', ...CLIENT_COMPANIES_FILTERS_INITIAL_VALUES },
  },
  [TableKey.USER_CLIENT_COMPANIES]: {
    ...initialTableState,
    filters: { [FilterKey.SEARCH]: '', ...USER_CLIENT_COMPANIES_FILTERS_INITIAL_VALUES },
  },
  [TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS]: {
    ...initialTableState,
    filters: { [FilterKey.SEARCH]: '', ...CLIENT_REQUESTS_FILTERS_INITIAL_VALUES },
  },
  [TableKey.CLIENT_REQUESTS]: {
    ...initialTableState,
    filters: { [FilterKey.SEARCH]: '', ...CLIENT_REQUESTS_FILTERS_INITIAL_VALUES },
  },
  [TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE]: {
    ...initialTableState,
    filters: {
      [FilterKey.SEARCH]: '',
      ...INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_FILTERS_INITIAL_VALUES,
    },
  },
  [TableKey.SITE_DOCUMENTS]: { ...initialTableState, filters: { [FilterKey.SEARCH]: '' } },
  [TableKey.SPECIFIC_SITE_DOCUMENTS]: { ...initialTableState, filters: { [FilterKey.SEARCH]: '' } },
  [TableKey.CLIENT_COMPANY_USERS]: { ...initialTableState, isReloading: true },
  [TableKey.CLIENT_COMPANY_BUSINESS_REFERENTS]: { ...initialTableState, isReloading: true },
  [TableKey.INVOICES]: {
    ...initialTableState,
    filters: {
      [FilterKey.SEARCH]: '',
      ...INVOICES_FILTERS_INITIAL_VALUES,
    },
  },
  [TableKey.BUSINESS_REFERENTS_MANAGEMENT]: {
    ...initialTableState,
    filters: { [FilterKey.SEARCH]: '', ...BUSINESS_REFERENTS_MANAGEMENT_FILTERS_INITIAL_VALUES },
  },
  [TableKey.INERT_WASTE_APPLICATION_REFERENTS]: {
    ...initialTableState,
    filters: {
      [FilterKey.SEARCH]: '',
      ...INERT_WASTE_APPLICATION_REFERENTS_FILTERS_INITIAL_VALUES,
    },
  },
};

const resetSelectTable = (state: Draft<TablesState>, tableKey: TableKey) => {
  if (state[tableKey].selectAll) state[tableKey].selectAll = false;
  if (state[tableKey].selectedIds.length > 0) state[tableKey].selectedIds = [];
};

const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    selectTableRow: (state, action: PayloadAction<{ tableKey: TableKey; selectedId: string }>) => {
      const selectedIds = state[action.payload.tableKey].selectedIds;
      const selectedId = action.payload.selectedId;
      if (selectedIds.includes(selectedId)) {
        state[action.payload.tableKey].selectedIds = selectedIds.filter((id) => id !== selectedId);
      } else {
        state[action.payload.tableKey].selectedIds = [...selectedIds, selectedId];
      }
    },
    selectAllTable: (state, action: PayloadAction<{ tableKey: TableKey; selectAll: boolean }>) => {
      state[action.payload.tableKey].selectAll = action.payload.selectAll;
      state[action.payload.tableKey].selectedIds = [];
    },
    setIsTableReloading: (
      state,
      action: PayloadAction<{ tableKey: TableKey; isReloading: boolean }>,
    ) => {
      state[action.payload.tableKey].isReloading = action.payload.isReloading;
    },
    setTableFilterByFilterKey: (
      state,
      action: PayloadAction<{
        tableKey: TableKey;
        filterKey: FilterKey | DeliveryNotesFiltersKeys;
        filterValue: string;
      }>,
    ) => {
      resetSelectTable(state, action.payload.tableKey);
      state[action.payload.tableKey].filters[action.payload.filterKey] = action.payload.filterValue;
    },
    setSpecificTableFilters: (
      state,
      action: PayloadAction<{ tableKey: TableKey; filters: TableFilters }>,
    ) => {
      resetSelectTable(state, action.payload.tableKey);
      state[action.payload.tableKey].filters = {
        ...state[action.payload.tableKey].filters,
        ...action.payload.filters,
      };
    },
    setTableSort: (state, action: PayloadAction<{ tableKey: TableKey; sort: TableSort }>) => {
      resetSelectTable(state, action.payload.tableKey);
      state[action.payload.tableKey].sort = action.payload.sort;
    },
    disableTableSort: (state, action: PayloadAction<{ tableKey: TableKey }>) => {
      resetSelectTable(state, action.payload.tableKey);
      state[action.payload.tableKey].sort = null;
    },
  },
});

export const {
  selectTableRow,
  selectAllTable,
  setIsTableReloading,
  setTableFilterByFilterKey,
  setSpecificTableFilters,
  setTableSort,
  disableTableSort,
} = tablesSlice.actions;
export const tablesReducer = tablesSlice.reducer;
