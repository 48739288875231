import { ClickAwayListener, Grow, Popper } from '@mui/material';
import { ReactElement, RefObject, useLayoutEffect, useState } from 'react';

interface Props {
  isOpen: boolean;
  close: () => void;
  anchorRef: RefObject<HTMLDivElement>;
  children: ReactElement;
  disablePortal?: boolean;
}

export const Popover = ({ isOpen, close, anchorRef, children, disablePortal = true }: Props) => {
  const [mounted, setMounted] = useState(false);

  useLayoutEffect(() => {
    const timer = requestAnimationFrame(() => {
      setMounted(true);
    });
    return () => {
      cancelAnimationFrame(timer);
      setMounted(false);
    };
  }, []);

  const handleClose = (event: globalThis.MouseEvent | globalThis.TouchEvent) => {
    if (anchorRef.current && event.target instanceof Node) {
      if (anchorRef.current.contains(event.target)) {
        return;
      }
    }
    close();
  };

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorRef.current}
      transition
      disablePortal={disablePortal}
      placement="bottom-end"
      sx={{
        zIndex: 2,
        position: 'fixed',
      }}
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            padding: 8,
          },
        },
      ]}
    >
      {({ TransitionProps }) =>
        mounted && (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center top' }}
            appear={isOpen}
            in={isOpen}
            timeout={150}
          >
            <div>
              <ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>
            </div>
          </Grow>
        )
      }
    </Popper>
  );
};
