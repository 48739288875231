import { Dialog, styled } from '@mui/material';
import { getSpacing } from 'stylesheet';

export const StyledFullScreenLoaderContainer = styled(Dialog)({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: getSpacing(6),
    height: getSpacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
