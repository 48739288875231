import { Drawer } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';

// Style Drawer directly
export const StyledDrawer = muiStyled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '80%',
    backgroundColor: colorPalette.primary,
    overflow: 'hidden',
    borderRadius: `0 ${getSpacing(2)} ${getSpacing(2)} 0`,
    padding: getSpacing(1.5),
  },
});

// Keep existing styled-components as they are
export const LegalCorporationLabel = styled(Link)`
  ${typography.p2_bold}
  text-decoration: none;
  color: ${colorPalette.white};
`;

export const MobileMenuItem = styled.div`
  padding: ${getSpacing(2.25)} ${getSpacing(2)};
`;
