import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { colorPalette, fontFamily, fontSize, getSizeInPx, getSpacing } from 'stylesheet';

export type DisplayStyle =
  | 'filledOrange'
  | 'filledRed'
  | 'filledBlue'
  | 'primary'
  | 'unfilledGrey'
  | 'unfilledGreyWithoutBorder'
  | 'unfilledOrange'
  | 'unfilledRed'
  | 'unfilledBlue'
  | 'underlineText'
  | 'text';

type StyledButtonProps = ButtonProps & {
  $margins?: string;
  $bigPaddings?: boolean;
  $paddings?: string;
  $displayStyle?: DisplayStyle;
  $isLoading?: boolean;
  $justifyContentLeft?: boolean;
  $widthInPx?: number;
  $customStyle?: React.CSSProperties;
  $fullWidth?: boolean;
};

const getFontWeight = ($displayStyle?: DisplayStyle) => {
  if (!$displayStyle) return 'normal';
  switch ($displayStyle) {
    case 'text':
      return 'bold';
    default:
      return 'normal';
  }
};

const getColor = ($displayStyle?: DisplayStyle) => {
  if (!$displayStyle) return colorPalette.greyMain;
  switch ($displayStyle) {
    case 'filledOrange':
    case 'filledRed':
    case 'filledBlue':
    case 'primary':
      return colorPalette.white;
    case 'unfilledGrey':
    case 'unfilledGreyWithoutBorder':
    case 'unfilledOrange':
    case 'unfilledRed':
    case 'unfilledBlue':
      return colorPalette.greyMain;
    case 'underlineText':
    case 'text':
      return colorPalette.secondary_600;
  }
};

const getBorder = ($displayStyle?: DisplayStyle) => {
  if (!$displayStyle) return 'unset';
  switch ($displayStyle) {
    case 'filledOrange':
    case 'unfilledOrange':
      return `solid 1px ${colorPalette.secondary_600}`;
    case 'unfilledGrey':
      return `solid 1px ${colorPalette.greyMain}`;
    case 'filledRed':
    case 'unfilledRed':
      return `solid 1px ${colorPalette.status_error}`;
    case 'filledBlue':
    case 'unfilledBlue':
      return `solid 1px ${colorPalette.blue_500}`;
    case 'primary':
      return `solid 1px ${colorPalette.primary}`;
    default:
      return 'unset';
  }
};

const getBackground = ($displayStyle?: DisplayStyle) => {
  if (!$displayStyle) return 'unset';
  switch ($displayStyle) {
    case 'filledOrange':
      return colorPalette.secondary_600;
    case 'filledRed':
      return colorPalette.status_error;
    case 'filledBlue':
      return colorPalette.blue_500;
    case 'primary':
      return colorPalette.primary;
    case 'unfilledGrey':
    case 'unfilledOrange':
    case 'unfilledRed':
    case 'unfilledBlue':
      return colorPalette.white;
    default:
      return 'unset';
  }
};

const getHoverBorder = ($displayStyle?: DisplayStyle) => {
  if (!$displayStyle) return 'unset';
  switch ($displayStyle) {
    case 'filledOrange':
      return `solid 1px ${colorPalette.secondary_300}`;
    case 'primary':
      return `solid 1px ${colorPalette.primary}`;
    case 'filledRed':
      return `solid 1px ${colorPalette.status_error_light}`;
    case 'unfilledGrey':
      return `solid 1px ${colorPalette.blue_400}`;
    case 'unfilledOrange':
      return `solid 1px ${colorPalette.secondary_600}`;
    case 'filledBlue':
      return `solid 1px ${colorPalette.blue_500}`;
    case 'unfilledGreyWithoutBorder':
      return 'none';
    case 'unfilledRed':
      return `solid 1px ${colorPalette.status_error}`;
    case 'unfilledBlue':
      return `solid 1px ${colorPalette.blue_500}`;
  }
};

const getHoverBackground = ($displayStyle?: DisplayStyle) => {
  if (!$displayStyle) return 'transparent';
  switch ($displayStyle) {
    case 'filledOrange':
      return colorPalette.secondary_300;
    case 'primary':
      return colorPalette.white;
    case 'filledRed':
      return colorPalette.status_error_light;
    case 'unfilledGrey':
      return colorPalette.blue_400;
    case 'unfilledOrange':
      return colorPalette.secondary_600;
    case 'filledBlue':
      return colorPalette.blue_500;
    case 'unfilledGreyWithoutBorder':
      return 'unset';
    case 'unfilledRed':
      return colorPalette.status_error;
    case 'unfilledBlue':
      return colorPalette.blue_500;
    case 'text':
      return colorPalette.secondary_100;
    case 'underlineText':
      return 'transparent';
  }
};

const getHoverColor = ($displayStyle?: DisplayStyle) => {
  if (!$displayStyle) return colorPalette.grey_400;
  switch ($displayStyle) {
    case 'primary':
      return colorPalette.primary;
    case 'unfilledGreyWithoutBorder':
      return colorPalette.grey_400;
    case 'underlineText':
    case 'text':
      return colorPalette.secondary_600;
  }
};

const getDisabledBorder = ($displayStyle?: DisplayStyle) => {
  if (!$displayStyle) return 'none';
  switch ($displayStyle) {
    case 'filledOrange':
    case 'filledRed':
    case 'filledBlue':
      return `solid 1px ${colorPalette.grey_400}`;
    case 'unfilledGrey':
    case 'unfilledOrange':
    case 'unfilledRed':
    case 'unfilledBlue':
      return `solid 1px ${colorPalette.grey_600}`;
    default:
      return 'none';
  }
};

const getDisabledBackground = ($displayStyle?: DisplayStyle) => {
  if (!$displayStyle) return 'unset';
  switch ($displayStyle) {
    case 'filledOrange':
    case 'filledRed':
    case 'filledBlue':
      return colorPalette.grey_400;
    case 'unfilledGrey':
    case 'unfilledOrange':
    case 'unfilledRed':
    case 'unfilledBlue':
      return colorPalette.white;
    default:
      return 'unset';
  }
};

const getStartIconColor = ($displayStyle?: DisplayStyle) => {
  if (!$displayStyle) return colorPalette.greyMain;
  switch ($displayStyle) {
    case 'filledOrange':
    case 'filledRed':
    case 'filledBlue':
    case 'primary':
      return colorPalette.white;
    case 'unfilledGrey':
    case 'unfilledGreyWithoutBorder':
      return colorPalette.greyMain;
    case 'unfilledOrange':
      return colorPalette.secondary_600;
    case 'unfilledRed':
      return colorPalette.status_error;
    case 'unfilledBlue':
      return colorPalette.blue_500;
    case 'text':
      return colorPalette.secondary_600;
  }
};

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop: PropertyKey) => !prop.toString().startsWith('$'),
})<StyledButtonProps>(({ theme, $displayStyle, $customStyle, ...props }) => ({
  fontFamily: fontFamily.main,
  fontSize: fontSize.p1,
  fontWeight: getFontWeight($displayStyle),
  height: getSpacing(5),
  minWidth: getSpacing(3),
  width: props.$widthInPx ? `${getSizeInPx(props.$widthInPx)}` : props.$fullWidth ? '100%' : 'auto',
  textTransform: 'inherit',
  letterSpacing: 'inherit',
  margin: props.$margins ?? '0',
  padding:
    props.$paddings ??
    (props.$bigPaddings
      ? `${getSpacing(1)} ${getSpacing(5)}`
      : `${getSpacing(1)} ${getSpacing(2)}`),
  borderRadius: getSpacing(1),

  '& .MuiButton-label': {
    justifyContent: props.$justifyContentLeft ? 'flex-start' : 'inherit',
    ...(props.$isLoading && {
      position: 'relative',
      visibility: 'hidden',
      '& .MuiCircularProgress-root': {
        visibility: 'visible',
      },
    }),
  },

  ...(props.$isLoading && {
    pointerEvents: 'none',
  }),

  color: getColor($displayStyle),
  border: getBorder($displayStyle),
  backgroundColor: getBackground($displayStyle),

  '&:hover,&:focus-visible,&:hover .MuiButton-startIcon,&:focus-visible .MuiButton-startIcon': {
    color: $displayStyle === 'text' ? colorPalette.secondary_600 : colorPalette.white,
    textDecoration: $displayStyle === 'underlineText' ? 'underline' : 'none',
  },

  // Consolidate all hover styles here with more specific selectors
  '&:hover, &:focus-visible': {
    border: getHoverBorder($displayStyle),
    backgroundColor: getHoverBackground($displayStyle),
    color: getHoverColor($displayStyle),

    '.MuiButton-startIcon': {
      color: getHoverColor($displayStyle), // We'll create this function
    },
  },

  '&:disabled': {
    border: getDisabledBorder($displayStyle),
    backgroundColor: getDisabledBackground($displayStyle),
    color: colorPalette.grey_600,
    '& .MuiButton-startIcon': {
      color: colorPalette.grey_600,
    },
  },

  '& .MuiButton-startIcon': {
    color: getStartIconColor($displayStyle),
    marginLeft: 0,
    marginRight: theme.spacing(1.5),
  },

  ...$customStyle,
}));
