import { ButtonProps } from '@mui/material';
import { Loader } from 'components/Loader/Loader';
import { frameHeight } from 'stylesheet';

import { StyledButton } from './Button.style';
import { AdditionalButtonProps } from './Button.types';

export const Button = ({
  displayStyle,
  margins,
  bigPaddings,
  paddings,
  startIcon,
  href,
  disabled,
  onClick,
  type,
  isLoading,
  children,
  justifyContentLeft,
  widthInPx,
  fullWidth,
  customStyle,
}: ButtonProps & AdditionalButtonProps) => {
  return (
    <StyledButton
      disableElevation
      disabled={disabled}
      href={href}
      onClick={onClick}
      startIcon={startIcon}
      type={type}
      $margins={margins}
      $bigPaddings={bigPaddings}
      $paddings={paddings}
      $displayStyle={displayStyle}
      $isLoading={isLoading}
      $justifyContentLeft={justifyContentLeft}
      $widthInPx={widthInPx}
      $customStyle={customStyle}
      $fullWidth={fullWidth}
    >
      {children}
      {isLoading && (
        <Loader sizeInPx={frameHeight.buttonInnerSize} color="inherit" position="absolute" />
      )}
    </StyledButton>
  );
};
