import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { GOOGLE_ANALYTICS_TRACKIND_ID } from 'services/api/constants';

// This constant are also defined in the tarteaucitron config that you can find in index.html
// If you modify them here, you need to modify the cookieName attribute and the job pushed
const GTAG_TARTEAUCITRON_KEY = 'gtag';
const TARTEAUCITRON_COOKIE_LABEL = 'tarteaucitron';

const isGtagEnabledByUser = (cookieTarteAuCitron: string) => {
  return (
    cookieTarteAuCitron
      .split('!')
      .find((tag) => tag.startsWith(`${GTAG_TARTEAUCITRON_KEY}=`))
      ?.split('=')[1] === 'true'
  );
};

export const useTarteAuCitron = () => {
  const [cookies] = useCookies([TARTEAUCITRON_COOKIE_LABEL]);

  const gtagEnabled = isGtagEnabledByUser(cookies[TARTEAUCITRON_COOKIE_LABEL] ?? '');

  useEffect(() => {
    (window as any)[`ga-disable-${GOOGLE_ANALYTICS_TRACKIND_ID}`] = !gtagEnabled;
  }, [gtagEnabled]);
};
