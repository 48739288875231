import { ROUTES } from 'routing/routes';
import { PLATFORM_LEGAL_CORPORATION_LABEL } from 'services/api/constants';

import { MenuItems } from './MenuItems';
import { LegalCorporationLabel, MobileMenuItem, StyledDrawer } from './MobileMenu.style';

type MobileMenuProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose }) => {
  return (
    <StyledDrawer open={isOpen} anchor="left" onClose={onClose}>
      <MobileMenuItem>
        <LegalCorporationLabel to={ROUTES.HOME}>
          {PLATFORM_LEGAL_CORPORATION_LABEL}
        </LegalCorporationLabel>
      </MobileMenuItem>

      <MenuItems onCloseMenu={onClose} />
    </StyledDrawer>
  );
};
