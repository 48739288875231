import { RootState } from '@redux/store';

export const getIsLoading = (rootState: RootState): boolean =>
  rootState.apiResponseStatusGlobalDisplay.isLoading;

export const getIsError = (rootState: RootState): boolean =>
  rootState.apiResponseStatusGlobalDisplay.isError;

export const getError = (rootState: RootState): string | undefined =>
  rootState.apiResponseStatusGlobalDisplay.error;
