import { apiFilterQueryParamGenerator } from 'services/apiFilterQueryParamGenerator/apiFilterQueryParamGenerator';

import { TableFilters, TableSort } from '../../../redux/Tables/filtersKeys';
import { apiClient } from '../apiClient';
import { PLATFORM_LEGAL_CORPORATION_ID } from '../constants';
import { ApiHydraPageableResponse } from '../types';
import {
  normalizeCreateClientRequestFormForRequestBody,
  normalizeCreateClientRequestMessageFormForRequestBody,
} from './normalizer';
import {
  ApiClientRequestInDetails,
  ApiClientRequestInList,
  ApiCountClientRequestUnread,
  CreateClientRequestForm,
  CreateClientRequestMessageForm,
} from './types';

const ENDPOINT_CLIENT_REQUESTS_COLLECTION = '/client-requests';
const ENDPOINT_CLIENT_REQUEST_ITEM = '/client-request';

export const createClientRequest = (
  createClientRequestForm: CreateClientRequestForm,
  clientCompanyId: number,
  isBackofficeIssuer: boolean,
) => {
  const data = normalizeCreateClientRequestFormForRequestBody(
    createClientRequestForm,
    clientCompanyId,
    isBackofficeIssuer,
  );
  return apiClient.request<null>('POST', `${ENDPOINT_CLIENT_REQUEST_ITEM}`, data);
};

export const createClientRequestMessage = (
  createClientRequestMessageForm: CreateClientRequestMessageForm,
  clientCompanyId: number,
  clientRequestId: number,
) => {
  const endpoint = `${ENDPOINT_CLIENT_REQUEST_ITEM}/create-message`;

  const data = normalizeCreateClientRequestMessageFormForRequestBody(
    createClientRequestMessageForm,
    clientCompanyId,
    clientRequestId,
  );

  return apiClient.request<null>('POST', `${endpoint}`, data);
};

export const getAllClientRequestsByPageAndClientCompany = (
  pageNumber: number,
  clientCompanyId: number,
  filters?: TableFilters,
  sort?: TableSort | null,
) => {
  const allFilters = {
    'legalCorporation.id': PLATFORM_LEGAL_CORPORATION_ID,
    'clientCompany.id': clientCompanyId.toString(),
    ...filters,
  };
  return apiClient.request<ApiHydraPageableResponse<ApiClientRequestInList>>(
    'GET',
    `${ENDPOINT_CLIENT_REQUESTS_COLLECTION}${apiFilterQueryParamGenerator.format({
      pageNumber,
      filters: allFilters,
      sort,
    })}`,
  );
};

export const getClientRequestById = (clientRequestId: number) => {
  return apiClient.request<ApiClientRequestInDetails>(
    'GET',
    `${ENDPOINT_CLIENT_REQUESTS_COLLECTION}/${clientRequestId}`,
  );
};

export const setApiClientRequestAsRead = (clientRequestId: number) => {
  const endpoint = `${ENDPOINT_CLIENT_REQUEST_ITEM}/set-read`;

  const allFilters = {
    clientRequestId: clientRequestId.toString(),
  };

  return apiClient.request<null>(
    'POST',
    `${endpoint}${apiFilterQueryParamGenerator.format({
      filters: allFilters,
    })}`,
  );
};

export const getNumberOfUnreadMessageForPermission = (permissionId: number) => {
  const endpoint = `/client-request-view/number-unread-messages`;

  const allFilters = {
    permissionId: permissionId.toString(),
  };

  return apiClient.request<ApiCountClientRequestUnread>(
    'GET',
    `${endpoint}${apiFilterQueryParamGenerator.format({ filters: allFilters })}`,
  );
};
